import React, { useEffect, useState } from 'react'

export default function Loader({ internal }) {

    const [loaded, setLoaded] = useState(internal)

    useEffect(() => {
        setTimeout(() => {
            console.log('run loaded')
            setLoaded(true)
            document.querySelector('.loader').classList.add('loaded')
        }, 1000)
    }, [])
    useEffect(() =>{
        console.log('laoded changed')
    },[loaded])


    return (
        <div className={'flex flex-column align-center justify-center w-100 bg-light-grey loader ' + (loaded ? 'loaded' : '')} >
        
        </div>
    )
}
