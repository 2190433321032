import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const HeroBanner = ({title, image}) => (
  <div className="flex align-bottom  justify-center p20 h-100vh-e bg-grey text-white pos-rel overflow-hidden m-mh100vh translate-3d "> 
      <h1 className="m0 h1 sm mb40 z-2 pos-rel m-text-center m-mb60 m-mb0 translate-3d ">{title}</h1>
      <div className="parallax--image bg-image">
        <GatsbyImage className='project-image bg-image initial--zoom br-08' image={image.gatsbyImageData} alt={title} />
      </div>    
  </div>
) 
export default HeroBanner
