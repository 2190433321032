import * as React from "react"
import Spacer from "../components/spacer"

const HeroBanner = ({title, content, author,spacer,spacerBottom = true,spacerTop = true}) => (
  <div className="text-banner p20"> 
     {spacerTop? <Spacer className={spacer} /> : null}
      <div className="max-600 ma text-center">
        <p className="h4 m0 mb40 black max-500 ma ">{title}</p>
        <div className="copy large">
          {content} 
        </div>
        <p className="m0 mt20 black medium">{author}</p>
      </div>
      {spacerBottom ?    <Spacer /> : null }

  </div>
) 
export default HeroBanner



