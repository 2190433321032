import React from "react"
import Layout from "../components/layout"
import Header from "../components/header"
import HeroBanner from "../components/heroBanner"
import InformationBanner from "../components/informationBanner"
import FeaturedProject from "../components/featuredProject"
import NewsCarousel from "../components/newsCarousel"
import TextBanner from "../components/textBanner"
import Quotation from "../components/quotation"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Loader from "../components/loader"
class ScrollingPage extends React.Component {
	constructor() {
		super();
		this.state = {
			imagesLoaded: false
		}
	}
	componentDidMount() {
		this.setState({
			imagesLoaded: true
		})

	
	}
	render() {
		var data = this.props.data.allDatoCmsHomepage.nodes[0];
		var news = this.props.data.allDatoCmsArticle.nodes;
		const internal = this.props.location?.state?.internal;
		console.log(internal)
		console.log(data.infoTitleOne)  
		return (
			<>
				<Loader internal={internal} />

				<Layout internal={this.props.location.state?.internal}>

					<SEO title="Atlantis Property Group" />


					<Header />

					<HeroBanner  title={data.heroTitle} image={data.heroImage} />

					<TextBanner title={data.textTitle} content={data.textContent} />

					<FeaturedProject caption='Featured Project' image={data.featuredProject.image} title={data.featuredProject.title} slug={data.featuredProject.slug} />

					<Quotation quote={data.quote} author={data.quoteAuthor} image={data.quoteImage} position={data.quotePosition} />

					<NewsCarousel news={news} />

					<TextBanner title={data.moreTitle} content={data.moreContent} />

					<InformationBanner 
						titleOne={data.infoTitleOne} 
						titleTwo={data.infoTitleTwo} 
						titleThree={data.infoTitleThree}
						contentOne={data.infoContentOne}
						contentTwo={data.infoContentTwo}
						contentThree={data.infoContentThree}
						image={data.infoImage} 
					/>

				</Layout>
			</>
		)
	}
}

export default ScrollingPage

export const query = graphql`
  query HomeQuery {
  	allDatoCmsHomepage {
    nodes {
      heroTitle
      textContent
      textTitle
      quoteImage {
      	gatsbyImageData
      }
      quote 
      quoteAuthor
      quotePosition
      infoContentOne
      infoContentThree
      infoContentTwo
      infoTitleOne
      infoTitleThree
	  moreContent
    	moreTitle
      infoTitleTwo
      infoImage {
	    	gatsbyImageData
	    }
      featuredProject {
      	slug
        title
      	image {
          gatsbyImageData
        }
      }
	    heroImage {
	        gatsbyImageData
	      }
    	}
  	}
  	allDatoCmsArticle {
	    nodes {
	      introduction
	      title
	      meta {
	        firstPublishedAt(formatString: "D MMM Y")
	      }
	    }
	  }
  }

`
