import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const InformationBanner = ({titleOne, titleTwo, titleThree, contentOne, contentTwo, contentThree, image}) => {
  console.log(titleOne)
  return(
  <>  
    <p className="m0 text-center mb40 medium black">Snapshot</p>
    <div className="flex align-center  justify-center p20 mh-66vw pt80 pb80 bg-grey text-white pos-rel overflow-hidden m-pt80 m-pb80"> 
        <div className="content-wrapper z-2 pos-rel text-center">
          <p className="m0 large">{titleOne}</p>
          <h1 className="m0 h2 mb80">{contentOne}</h1> 
          <p className="m0 large">{titleTwo}</p>
          <h1 className="m0 h2 mb80">{contentTwo}</h1> 
          <p className="m0 large">{titleThree}</p>
          <h1 className="m0 h2">{contentThree}</h1> 
        </div>
        <GatsbyImage className='bg-image br-08' image={image.gatsbyImageData} alt='Statistics'/>
    </div> 
  </>)
}
export default InformationBanner
