import * as React from "react"
import ProjectBanner from "../components/projectBanner"

const FeaturedProject = ({caption,...props}) => (
  <div className="featured--project text-center">
    <p className="m0 medium black mb20">{caption}</p>
    <ProjectBanner {...props} />
  </div> 
) 
export default FeaturedProject
