import React from "react"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from "swiper";
import 'swiper/css';


const NewsCarousel = ({ news }) => {

  const swiperOptions = {
    modules: [Navigation, Pagination],
    pagination: { clickable: true, type: 'progressbar', el: `.swiper-progress` },
    navigation: { nextEl: `div.next`, prevEl: `div.previous`, },
    breakpoints: {
        0: {
          slidesPerView: 'auto'
        },
        991: {
            slidesPerView: 3,
        }
    }
  }

  const slides = news.map(({ title, meta, introduction }, index) => {
    return (
      <SwiperSlide className="m-max-335 " key={index}>
        <div className="bg-slate h-100 text-white text-center p40 flex ">
          <div className="max-400 mh-25vw m-mh-65vw ma h-100 flex flex-wrap">
            <div className="m0 max-300 ma mt0">
              {introduction}
              <p className="m0 mt20 grey-100">{meta.firstPublishedAt}</p>
            </div>
            <p className="m0 h3 sm mta w-100 m-h4 ">{title}</p>
          </div>
        </div>
      </SwiperSlide> 
    )
  })

  //Todo remove duplicated slides

  return (
    <div className="news--carousel p20">
      <p className="m0 text-center medium black mb40">News & Updates</p>
      <div className="carousel-internal max-1600 pr40 ma overflow-visible">
        <Swiper {...swiperOptions} spaceBetween={20}>
          {slides}
          {slides}
        </Swiper>
        <div className="swiper--navigation flex pt40">
          <div className="swiper-nav previous">
          <svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7 11.6468L2.35319 7L7 2.35319L7 0L3.0598e-07 7L7 14L7 11.6468Z" fill="#616161" />
            </svg>
          </div>
          <div className="progress-wrapper">
            <div className="swiper-progress flex">
             
            </div>
          </div>
          <div className="swiper-nav next  ">
          <svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M-1.02861e-07 11.6468L4.64681 7L-5.09098e-07 2.35319L-6.11959e-07 0L7 7L0 14L-1.02861e-07 11.6468Z" fill="#616161" />
              </svg>


          </div>
        </div>
      </div>

    </div>
  )
}
export default NewsCarousel
